<template>
  <div>Page not found</div>
</template>

<script>
  export default {
    beforeCreate() {
      //this.$router.push({ name: 'Error', params: { message: "We were unable to locate the requested resouce." } });
    },
  };
</script>
